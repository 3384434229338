import LogoEmilia from '~/components/Layout/logoFooterEmiliaWhite.png';
import LogoReggio from '~/components/Layout/svg/logoReggio-footer.svg';
import React from 'react';
import { getSiteProperty } from '@italia/helpers';
import { useIntl } from 'react-intl';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */
const LogoFooter = () => {
  const intl = useIntl();
  const logoid = __CLIENT__
    ? window.env.RAZZLE_LOGOID
    : process.env.RAZZLE_LOGOID;
  return (
    <img
      src={logoid === 'emilia' ? LogoEmilia : LogoReggio}
      width="279"
      height="82"
      className={`icon ${logoid === 'emilia' ? 'logo-emilia' : 'logo-reggio'}`}
      alt={getSiteProperty('siteTitle', intl.locale)}
    />
  );
};

export default LogoFooter;
