/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import applyItaliaConfig from '@italia/config/italiaConfig';
import { VirtualAssistant } from '@package/components';
import '@plone/volto/config';

import {
  ContentImage,
  EventoCosE,
  EventoLuoghi,
  EventoDateOrari,
  EventoCosti,
  EventoDocumenti,
  EventoContatti,
  EventoPadreEFigli,
  EventoUlterioriInformazioni,
  EventoSponsors,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

export default function applyConfig(voltoConfig) {
  const logoid = __CLIENT__
    ? window.env.RAZZLE_LOGOID
    : process.env.RAZZLE_LOGOID;
  let config = applyItaliaConfig(voltoConfig);
  config.settings.italiaThemeViewsConfig = {
    ...config.settings.italiaThemeViewsConfig,
    imagePosition: 'documentBody', // possible values: afterHeader, documentBody
    Event: {
      sections: [
        {
          /* HEADER IMAGE */

          component: ContentImage,
          props: { position: 'documentBody' },
        },
        { /* COS'è */ component: EventoCosE },
        { /* LUOGHI */ component: EventoLuoghi },
        { /* DATE E ORARI */ component: EventoDateOrari },
        { /* COSTI */ component: EventoCosti },
        { /* DOCUMENTI */ component: EventoDocumenti },
        { /* EVENTS */ component: EventoPadreEFigli },
        { /* SPONSORS */ component: EventoSponsors },
        { /* ULTERIORI INFORMAZIONI */ component: EventoUlterioriInformazioni },
        { /* CONTATTI */ component: EventoContatti },
      ],
    },
  };
  config.settings.siteProperties = {
    ...config.settings.siteProperties,
    siteTitle: 'Camera di Commercio Reggio Emilia',
    siteSubtitle: '',
    parentSiteTitle: null, //'Unioncamere',
    parentSiteURL: null, //'http://www.unioncamere.gov.it/',
    subsiteParentSiteTitle: 'Camera di Commercio Reggio Emilia',
  };
  if (logoid === 'emilia') {
    config.settings.siteProperties = {
      ...config.settings.siteProperties,
      headerslimTertiaryMenu: {
        it: [
          {
            url: '/menu-principale/prenotazioni-on-line',
            title: 'Prenotazioni on line',
          },
          {
            url: '/menu-principale/la-camera/sipa-sito-pagamenti-online-pagopa',
            title: 'Pagamenti on line',
          },
          {
            url: 'https://camerainforma.camcom.it/emilia/',
            title: 'Newsletter',
          },
        ],
      },
    };

    config.settings['volto-gdpr-privacy'] = {
      ...config.settings['volto-gdpr-privacy'],
      defaultPanelConfig: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
        last_updated: '2023-06-06T11:30:00+00:00',
        technical: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
          choices: [
            {
              config_key: 'MATOMO',
              text: {
                it: {
                  title: 'Matomo',
                  description:
                    "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
                },
                en: {
                  title: 'Matomo',
                  description:
                    'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
                },
                fr: {
                  title: 'Matomo',
                  description:
                    "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
                },
              },
            },
            /*...config.settings[
            'volto-gdpr-privacy'
          ].defaultPanelConfig.technical.choices.filter(
            (f) =>
              f.config_key !== 'GANALYTICS' && f.config_key !== 'GRECAPTCHA',
          ),*/
          ],
        },
      },
    };
  } else {
    //Reggio Emilia Config
    config.settings['volto-gdpr-privacy'] = {
      ...config.settings['volto-gdpr-privacy'],
      defaultPanelConfig: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
        last_updated: '2023-16-06T14:00:00+00:00',
        text: {
          //Text that is shown when the banner appears
          it: {
            title: 'Usiamo i cookie',
            description:
              "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti. Utilizza anche cookie di profilazione dell'utente per fini statistici <strong>e per l'utilizzo dell'assistente virtuale Emily</strong>. Per i cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Impostazioni'. Per saperne di più, su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href='/privacy-policy'>Cookie Policy</a>.",
          },
          en: {
            title: 'We use cookies',
            description:
              "This site uses technical navigation and session cookies to ensure a better site navigation service, and analytical cookies to collect information on the use of the site by users. It also uses user profiling cookies for statistical purposes <b>and for the use of the virtual assistant Emilyz</b>. For profiling cookies you can decide whether to enable them or not by clicking on the 'Settings' button. To find out more, on how to disable cookies or enable only some of them, consult our <a href='/en/privacy-policy'>Cookie Policy</a>.",
          },
          fr: {
            title: 'We use cookies',
            description:
              "Ce site utilise des cookies techniques de navigation et de session pour assurer un meilleur service de navigation sur le site, et des cookies analytiques pour collecter des informations sur l'utilisation du site par les utilisateurs. Il utilise également des cookies de profilage des utilisateurs à des fins statistiques <b>et pour l'utilisation de l'assistante virtuelle Emily</b>. Pour les cookies de profilage, vous pouvez décider de les activer ou non en cliquant sur le bouton « Paramètres ». Pour en savoir plus, sur la façon de désactiver les cookies ou d'en activer seulement certains, consultez notre <a href='/fr/privacy-policy'>Politique relative aux cookies</a>.",
          },
        },
        technical: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
          choices: [
            {
              config_key: 'MATOMO',
              text: {
                it: {
                  title: 'Matomo',
                  description:
                    "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
                },
                en: {
                  title: 'Matomo',
                  description:
                    'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
                },
                fr: {
                  title: 'Matomo',
                  description:
                    "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
                },
              },
            },
            /*...config.settings[
            'volto-gdpr-privacy'
          ].defaultPanelConfig.technical.choices.filter(
            (f) =>
              f.config_key !== 'GANALYTICS' && f.config_key !== 'GRECAPTCHA',
          ),*/
          ],
        },
      },
    };
  }

  //Emilly Virtual Assistant - Works only for Reggio Emillia at the moment
  /* config.settings.appExtras = [
    ...(config.settings.appExtras || []),
    {
      match: '',
      component: VirtualAssistant,
    },
  ]; */
  return config;
}
